label {
    font-size: 12px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
}

.filter-container {
    background-color: transparent;
}

@media (max-width: 380px) {
    .filters-inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0 10px;
    }
    .filter-fields-group-item {
        width: 100%;
    }
    .group-date {
        display: flex;
        flex-direction: column;
    }
    .group-fields {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

@media (min-width: 381px) and (max-width: 411px) {
    .filters-inputs {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 10px;
    }
    .filter-fields-group-item {
        width: 100%;
    }
    .group-date {
        display: flex;
        flex-direction: column;
    }
    .group-fields {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

@media (min-width: 389px) and (max-width: 600px) {
    .filters-inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .filter-fields-group-item {
        width: 100%;
    }
    .group-date {
        display: flex;
        flex-direction: column;
    }
    .group-fields {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
    .filters-inputs {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 10px;
    }
    .filter-fields-group-item {
        width: 100%;
    }
    .group-date {
        display: flex;
        flex-direction: column;
    }
    .group-fields {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

@media (min-width: 1025px) {
    .filters-inputs {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 10px;
    }
    
    .group-date {
        display: flex;
        flex-direction: column;
    }
    .group-fields {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

.group-fields {
    padding: 0;
    width: 100%;
}

.ant-picker {
    width: 100% !important;
}

.date-field {
    width: 100%;
}

.ant-form-item {
    margin-bottom: 5px !important;
}

.buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0px 0;
    gap: 10px;
}

.group-state-city {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.state-city-field {
    width: 100%;
}