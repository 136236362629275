
@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

// There are some major variables below, 
// all less variables could be found in 
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@primary-color: #1669b6; // primary color for all components
@link-color: #1669b6; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 12px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

@layout-sider-background: #003D85; // Cor da sidebar
@layout-trigger-background: #003D85; // Cor do expansor no rodapé
@menu-bg: #003D85; // Cor do background do menu
@menu-item-color: white; // Cor das fontes dos itens de menu
@menu-item-active-bg: #0059C2; // Cor dos itens de menu ativos
.ant-menu-item-selected a {
    color: white;
}
.anticon-unordered-list {
    color: white;
}
.ant-menu-sub{
    background: #003D85 !important;
}
.filter-title{
    margin: 10px;
}
.search-button {
    float: right;
}